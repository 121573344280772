import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Loader, Table, Uploader } from 'rsuite'
import * as XLSX from 'xlsx/xlsx.mjs'
import HomeButton from '../components/HomeButton'
import LanguageButton from '../components/LanguageButton'
import PreviewPane from '../components/PreviewPane'
import StatusLink from '../components/statusLink'
import { delTrans, downloadTrans, getAllTrans, saveTrans, viewTrans } from '../http/API'
import tempStyle from '../components/inTime'

function DataAcquisition () {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [transactions, setTrans] = useState()
  const [previewTrans, setPreviewTrans] = useState()
  const [transShow, setTransShow] = useState(true)
  const [FL, setFileList] = useState([])
  const [sureness, sure] = useState(false)

  const [updateKey, update] = useState(0)
  const [tableJSON, setTableJSON] = useState()

  useEffect(async () => {
    await getAllTrans().then(e => {
      if (e.response.success) {
        setTrans(e.response.trans)
      } else {
        Alert.error(e.response.message)
      }
    })
  }, [updateKey])

  const save = async () => {
    setLoading(true)
    await saveTrans(tableJSON).then(e => {
      setLoading(false)
      if (e.response.success) {
        Alert.success(t('data.acq.save_success'))
        setTableJSON()
        setTransShow(true)
        update(updateKey + 1)
      } else {
        Alert.error(e.response.message)
      }
    })
  }

  const handleXLSX = (f) => {
    if (f.length > 0) {
      setTransShow(false)
      setLoading(true)
      const reader = new FileReader()
      setTableJSON()
      reader.onload = (evt) => {
        const bstr = evt.target.result
        const wb = XLSX.read(bstr, { type: 'binary' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const jsonSheet = XLSX.utils.sheet_to_json(ws, { header: 1 })
        if (jsonSheet.length > 2000) {
          Alert.error(t('data.acq.limit'))
        } else {
          const data = jsonSheet.map((g, key) => {
            return (
              {
                tableCellid: key,
                gender: g[0] === 1,
                age: g[1],
                HB: g[2],
                RBC: g[3],
                HCT: g[4],
                Fer: g[5],
                B12: g[6],
                notes: g[7]
              }
            )
          }
          ).slice(1, -1)
          setTableJSON(data)
        }
        setLoading(false)
        setFileList([])
      }
      reader.readAsBinaryString(f.pop().blobFile)
    }
  }

  const downloadTransaction = async (id) => {
    setLoading(true)
    await downloadTrans(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        const fileData = res.response.file
        const uint8Array = new Uint8Array(atob(fileData).split('').map(char => char.charCodeAt(0)))
        const blob = new Blob([uint8Array], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = res.response.name
        link.click()
      } else {
        Alert.error(res.response.message)
      }
    })
  }
  const viewTransaction = async (id) => {
    setLoading(true)
    await viewTrans(id).then(res => {
      setLoading(false)
      if (res.response.success) {
        setPreviewTrans(res.response.data)
      } else {
        Alert.error(res.response.message)
      }
    })
  }
  const deleteTransaction = async (id) => {
    if (!sureness) {
      sure(true)
      Alert.warning(t('data.acq.del_warning'), 5000)
      setTimeout(_ => {
        sure(false)
      }, [6000])
    } else {
      sure(false)
      await delTrans(id).then(res => {
        if (res.response.success) {
          Alert.success(t('data.acq.del_success'))
          update(updateKey + 1)
        } else {
          Alert.error(res.response.message)
        }
      })
    }
  }

  return (
    <div className='datamodal-wrapper flex-column flex-stretch' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ background: 'white' }}>
        <h3>
          <HomeButton />
          {t('profile.data_acquisition')}
          <LanguageButton />
        </h3>
        <hr />
        {transactions &&
          <Button appearance='primary' style={{ width: '100%', fontWeight: 'bolder' }} onClick={() => { setTransShow(!transShow); setTableJSON() }}>{t(transShow ? 'data.acq.hidetrans' : 'data.acq.showtrans')}</Button>
        }
        <br />
        <a href="/InputExample.xlsx"><button>{t('data.acq.example')}</button></a>
        <Uploader
          accept='.xlsx'
          type='file'
          autoUpload={false}
          fileList={FL}
          onChange={(e) => handleXLSX(e)}>
          <Button appearance='primary' style={{ width: '100%', fontWeight: 'bolder' }}>{t('data.acq.upload')}</Button>
        </Uploader>
      </div>
      {tableJSON &&
        <div className='calcmodal-body data'>
          <button onClick={save}>{t('data.acq.save')}</button>
          <Table
            height={500}
            // autoHeight
            width={1000}
            hover
            data={tableJSON}
          >
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>{t('stats.gender')}</Table.HeaderCell>
              <Table.Cell>{rowData => rowData.gender ? t('common.gender.f') : t('common.gender.m')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>{t('stats.age')}</Table.HeaderCell>
              <Table.Cell dataKey="age" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>HB</Table.HeaderCell>
              <Table.Cell dataKey="HB" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>RBC</Table.HeaderCell>
              <Table.Cell dataKey="RBC" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>HCT</Table.HeaderCell>
              <Table.Cell dataKey="HCT" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>Fer</Table.HeaderCell>
              <Table.Cell dataKey="Fer" />
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>B12</Table.HeaderCell>
              <Table.Cell dataKey="B12" />
            </Table.Column>
            <Table.Column flexGrow={5} align="left" fixed>
              <Table.HeaderCell>{t('stats.notes')}</Table.HeaderCell>
              <Table.Cell dataKey="notes" />
            </Table.Column>
          </Table>
        </div>
      }
      {transShow &&
        <div className='calcmodal-body data' style={{ background: 'white' }}>
          <Table
            height={500}
            // autoHeight
            width={1200}
            hover
            rowHeight={70}
            data={transactions}
            className='trans-table'
            renderEmpty={() => <p style={{ margin: 'auto', width: 'fit-content' }}>Нет данных для просмотра!</p>}
          >
            <Table.Column flexGrow={0.5} align="center" fixed>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.Cell dataKey="id" />
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.created_at')}</Table.HeaderCell>
              <Table.Cell>{rowData => new Date(rowData.createdAt).toLocaleString('ru')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.updated_at')}</Table.HeaderCell>
              <Table.Cell>{rowData => new Date(rowData.updatedAt).toLocaleString('ru')}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.last_stage')}</Table.HeaderCell>
              <Table.Cell>{rowData => <StatusLink stage={rowData.status}/>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.next_stage')}</Table.HeaderCell>
              <Table.Cell>{rowData => <StatusLink stage={rowData.status + 1}/>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>{t('data.acq.download')}</Table.HeaderCell>
              <Table.Cell>{rowData => <Button appearance='default' onClick={() => downloadTransaction(rowData.id)} color='blue'>{t('common.download')}</Button>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1.5} align="center" fixed>
              <Table.HeaderCell>{t('data.common.preview')}</Table.HeaderCell>
              <Table.Cell>{rowData => <Button appearance='default' onClick={() => viewTransaction(rowData.id)} color='cyan'>{t('data.common.preview_button')}</Button>}</Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} align="center" fixed>
              <Table.HeaderCell>{t('data.common.delete')}</Table.HeaderCell>
              <Table.Cell>{rowData => <Button appearance='default' onClick={() => deleteTransaction(rowData.id)} color='red' >{t('data.common.delete_button')}</Button>}</Table.Cell>
            </Table.Column>
          </Table>
        </div>
      }
      <PreviewPane previewTrans={previewTrans} setPreviewTrans={setPreviewTrans} />
      {loading && <Loader backdrop content={t('common.loading')} vertical />}
    </div>
  )
}

export default DataAcquisition
