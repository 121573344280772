import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { Button } from 'rsuite'
import { Link } from 'react-router-dom'

const HomeButton = () => {
  return (
    <Link to='/profile' style={{ marginRight: '10px' }}>
      <Button appearance='primary' className='language-button' title='В профиль'>
      <FontAwesomeIcon icon={FA.faHome} />
      </Button>
    </Link>
  )
}

export default HomeButton
