import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Alert, Button, Input, InputGroup } from 'rsuite'
import HomeButton from '../components/HomeButton'
import LanguageButton from '../components/LanguageButton'
import { diagnose } from '../http/API'
import tempStyle from '../components/inTime'

function Diagnose () {
  const { t } = useTranslation()

  const [updateKey, update] = useState(0)
  const [data, setData] = useState({})
  const submit = async () => {
    if (data.HB && data.RBC && data.HCT) {
      try {
        await diagnose(data).then((res) => {
          const response = res.response
          if (response.success) {
            Alert.success(response.message)
            update(() => updateKey + 1)
          } else {
            Alert.error(response.message)
          }
        })
      } catch (e) {
        Alert.error(t('error.connectionerror'))
      }
    } else {
      Alert.error(t('error.checkdata'))
    }
  }

  return (
    <div className='datamodal-wrapper' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data' style={{ background: 'white' }}>
        <h3><HomeButton />{t('diagnosis.title')}<LanguageButton /></h3>
        <hr />
        <InputGroup>
          <Input type='number' placeholder={t('diagnosis.hb')} onChange={e => setData({ ...data, HB: e })} />
          <InputGroup.Addon>{t('common.measure.gram')}/{t('common.measure.liter')}</InputGroup.Addon>
        </InputGroup><br />
        <InputGroup>
          <Input type='number' placeholder={t('diagnosis.hct')} min='0' max='100' onChange={e => setData({ ...data, HCT: e / 100 })} />
          <InputGroup.Addon>%</InputGroup.Addon>
        </InputGroup><br />
        <InputGroup>
          <Input type='number' placeholder={t('diagnosis.rbc')} onChange={e => setData({ ...data, RBC: e })} />
          <InputGroup.Addon>10<sup>12</sup>/{t('common.measure.liter')}</InputGroup.Addon>
        </InputGroup><br />
        <InputGroup>
          <Input type='number' placeholder={t('diagnosis.fer')} onChange={e => setData({ ...data, Fer: e })} />
          <InputGroup.Addon>{t('common.measure.micro')}{t('common.measure.gram')}/{t('common.measure.liter')}</InputGroup.Addon>
        </InputGroup><br />
        <InputGroup>
          <Input type='number' placeholder='B12' onChange={e => setData({ ...data, B12: e })} />
          <InputGroup.Addon>{t('common.measure.nano')}{t('common.measure.gram')}/{t('common.measure.liter')}</InputGroup.Addon>
        </InputGroup><br />
        <Button appearance='primary' style={{ fontWeight: 'bolder' }} onClick={submit}>
          {t('diagnosis.submit')}
        </Button>
        <Link to='/history'><Button appearance='primary'>{t('diagnosis.history')}</Button></Link>
      </div>
    </div>
  )
}

export default Diagnose
