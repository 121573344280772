import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as FA from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import { Button } from 'rsuite'

const LanguageButton = () => {
  const { t, i18n } = useTranslation()
  return (
    <Button appearance='primary' className='language-button' onClick={() => i18n.changeLanguage(i18n.language === 'ru' ? 'en' : 'ru')} title='Смена языка' style={{ marginLeft: '10px' }}>
      <FontAwesomeIcon icon={FA.faGlobe} />
      {i18n.language.toUpperCase()}
    </Button>
  )
}

export default LanguageButton
