import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const StatusLink = ({ stage }) => {
  const { t, i18n } = useTranslation()
  const status = {
    0: {
      title: t('data.acq.stage'),
      color: '#a4b4ff',
      link: '/data_acq'
    },
    1: {
      title: t('data.proc.stage'),
      color: '#ffa4a4',
      link: '/data_proc'
    },
    2: {
      title: t('data.eval.stage'),
      color: '#f7ffa4',
      link: '/data_eval'
    },
    3: {
      title: t('data.class.stage'),
      color: '#b0ffa4',
      link: '/data_class'
    },
    4: {
      title: t('common.ready'),
      color: '#00ff00'
    }
  }
  const stat = status[stage]
  return (
    <>
      {stat.link
        ? <Link className='trans-status' to={stat.link} style={{ background: stat.color }}>{stat.title}</Link>
        : <p className='trans-status' style={{ background: stat.color }}>{stat.title}</p>}
    </>
  )
}

export default StatusLink
