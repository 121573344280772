import { Modal, Progress, Table, Button, Checkbox, Whisper } from 'rsuite'
import { useTranslation } from 'react-i18next'

function PreviewPane ({ previewTrans, setPreviewTrans }) {
  const { t } = useTranslation()
  const decon = (i) => i.split('.').pop()
  const converter = {
    true: '1',
    false: '0'
  }
  const widths = {
    notes: 2,
    result_anemia: 2,
    result_macro: 2,
    result_micro: 2,
    result_normo: 2,
    result_AHZ: 2,
    result_ZhDA: 2,
    result_B12: 2
  }
  const indexes = ['MCH', 'MCHC', 'MCV', 'MCH_macro', 'MCV_macro', 'MCH_micro', 'MCV_micro']
  const meta = ['age', 'age_group', 'gender']
  const input = ['HB', 'HCT', 'RBC', 'Fer', 'B12']
  const mathData = [
    'mMCH',
    'mMCHC',
    'mMCV',
    'mHB',
    'mRBC',
    'mHCT',
    'M',
    'mMacro',
    'mMicro',
    'mNormo',
    'mAHZ',
    'mZhDA',
    'mB12',
    'result_anemia',
    'result_macro',
    'result_micro',
    'result_normo',
    'result_AHZ',
    'result_ZhDA',
    'result_B12'
  ]
  const exclude = {
    0: [],
    1: [],
    2: [...meta, ...input],
    3: [...indexes, ...meta, ...input]
  }
  const statusDict = {
    HB: 0,
    age_group: 1,
    M: 2,
    result_anemia: 3
  }

  const getStatus = () => {
    let s = 0
    Object.keys(statusDict).map(i => {
      const keys = Object.keys(previewTrans[0]).map(i => decon(i))
      if (keys.includes(i)) s = statusDict[i]
    })
    return s
  }

  const cellContent = (i, data) => {
    if (i.split('.').length > 1) {
      if (!mathData.includes(decon(i))) return data
      else {
        if (typeof data !== 'boolean') {
          return <Progress.Circle percent={ (data * 100).toFixed(1)} strokeColor='red' className='eval-progress'/>
        } else {
          console.log(decon(i))
          return <Checkbox disabled defaultChecked={data} />
        }
      }
    } else {
      return typeof data === 'boolean' ? converter[data] : data
    }
  }
  const cellHeader = (i) => {
    let val
    return <p>{decon(i)}</p>
  }
  return (
    <Modal full show={previewTrans} onHide={() => setPreviewTrans()}>
      <Modal.Header>
        <Modal.Title>{t('data.common.preview_title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table
          data={previewTrans}
          autoHeight
          // height={1000}
          virtualized
          affixHeader
          affixHorizontalScrollbar
          rowHeight={previewTrans && Object.keys(previewTrans[0]).includes('eval_datum.MCH') ? 100 : 50}
        >
          {previewTrans &&
            Object.keys(previewTrans[0]).map(i => {
              if (!exclude[getStatus()].includes(decon(i))) {
                return (
                  <Table.Column key={i} flexGrow={widths[i] || 1} align="center" fixed>
                    <Table.HeaderCell>{cellHeader(i)}</Table.HeaderCell>
                    <Table.Cell>{rowData => cellContent(i, rowData[i])}</Table.Cell>
                  </Table.Column>
                )
              }
            }
            )
          }
        </Table>
      </Modal.Body>
    </Modal>
  )
}

export default PreviewPane
