import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next'
import { Alert, Input } from 'rsuite'
import { Link } from 'react-router-dom'

function Title () {
  const { t, i18n } = useTranslation()
  const [data, setData] = useState({})
  return (
    <div className='title-wrap'>
      <h5>Паспорт Здоровья</h5>
      <Link to='/auth'>Авторизоваться</Link>
    </div>
  )
}

export default Title
