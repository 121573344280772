import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Title from './pages/Title'
import Profile from './pages/Profile'
import React from 'react'
import AdminSettings from './pages/AdminSettings'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

import { verifyToken } from './http/API'
import Auth from './pages/Auth'
import Diagnose from './pages/Diagnose'
import History from './pages/History'
import PatientPassport from './pages/PatientPassport'
import Stats from './pages/Stats'
import DataAcquisition from './pages/DataAcquisition'
import DataProcessing from './pages/DataProcessing'
import DataEvaluation from './pages/DataEvaluation'
import DataClassification from './pages/DataClassification'

function App () {
  const isVerifiedToken = async () => {
    await verifyToken().then(res => {
      if (res.response.success) {
        Cookies.set('first_name', res.response.first_name)
        Cookies.set('last_name', res.response.last_name)
        Cookies.set('email', res.response.email)
      } else {
        Cookies.remove('token')
        window.location.reload()
      }
    })
  }
  const token = Cookies.get('token')
  let userRole
  try {
    userRole = token ? jwtDecode(token).role : undefined
  } catch {
    Cookies.remove('token')
    window.location.reload()
  }
  if (token) {
    try {
      const isTokenExpired = t => Date.now() >= (JSON.parse(atob(t.split('.')[1]))).exp * 1000
      if (isTokenExpired(token)) {
        Cookies.remove('token')
        window.location.reload()
      }
      isVerifiedToken()
      console.log('token check')
    } catch {
      Cookies.remove('token')
      window.location.reload()
    }
  }
  return (
    <>
      <Router>
        <Switch>
          <Route path='/auth' component={Auth} />
          <Route path='/profile' component={userRole ? Profile : Auth} />
          <Route path='/diagnosis' component={userRole ? Diagnose : Auth} />
          <Route path='/history' component={userRole ? History : Auth} />
          <Route path='/data_acq' component={userRole ? DataAcquisition : Auth} />
          <Route path='/data_proc' component={userRole ? DataProcessing : Auth} />
          <Route path='/data_eval' component={userRole ? DataEvaluation : Auth} />
          <Route path='/data_class' component={userRole ? DataClassification : Auth} />
          <Route path='/patients' component={['ADMIN', 'DOCTOR'].includes(userRole) ? PatientPassport : Auth} />
          <Route path='/stats' component={['ADMIN', 'DOCTOR'].includes(userRole) ? Stats : Auth} />
          <Route path='/admin' component={userRole === 'ADMIN' ? AdminSettings : Profile} />
          <Route exact path='*' component={Title} />
        </Switch>
      </Router>
    </>
  )
}

export default App
