import * as FA from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Alert, Button } from 'rsuite'
import LanguageButton from '../components/LanguageButton'
import tempStyle from '../components/inTime'
import { regenKey } from '../http/API'

const Profile = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [keyUpdated, setKeyUpdated] = useState()
  const handleLogOut = () => {
    Cookies.remove('token')
    window.location.reload()
  }
  const token = Cookies.get('token')
  const userRole = token ? jwtDecode(token).role : undefined
  const decyph = token && jwtDecode(token)
  const tiles = [
    {
      title: t('profile.adminpanel'),
      subtitle: t('profile.desc.admin'),
      link: '/admin',
      icon: FA.faCheckCircle,
      roles: ['ADMIN'],
      inDev: false
    },
    {
      title: t('profile.data_acquisition'),
      subtitle: t('profile.desc.data_acquisition'),
      link: '/data_acq',
      icon: FA.faDatabase,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.data_processing'),
      subtitle: t('profile.desc.data_processing'),
      link: '/data_proc',
      icon: FA.faMicrochip,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.data_eval'),
      subtitle: t('profile.desc.data_eval'),
      link: '/data_eval',
      icon: FA.faCalculator,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.data_class'),
      subtitle: t('profile.desc.data_class'),
      link: '/data_class',
      icon: FA.faProjectDiagram,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.diagnosis'),
      subtitle: t('profile.desc.diagnosis'),
      link: '/diagnosis',
      icon: FA.faAmbulance,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.stats'),
      subtitle: t('profile.desc.stats'),
      link: '/stats',
      icon: FA.faChartArea,
      roles: ['DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.patients'),
      subtitle: t('profile.desc.patients'),
      link: '/patients',
      icon: FA.faAddressCard,
      roles: ['DOCTOR', 'ADMIN'],
      inDev: false
    },
    {
      title: t('profile.history'),
      subtitle: t('profile.desc.history'),
      link: '/history',
      icon: FA.faHistory,
      roles: ['USER', 'DOCTOR', 'ADMIN'],
      inDev: false
    }
  ]
  const copyKey = async () => {
    await regenKey().then(e => {
      setKeyUpdated(e.response.apikey)
      if (e.response.success) {
        navigator.clipboard.writeText(e.response.apikey)
        Alert.success(t('profile.apikey.alert'))
      }
    })
  }
  return (
    <>
      <div className={loading ? 'backdrop-loading' : 'hidden'}>
        <FontAwesomeIcon icon={FA.faSpinner} spin size='10x' />
      </div>
      <div className={loading ? 'hidden' : 'profile-wrapper'} style={tempStyle}>
        {decyph &&
          <div id='user'>
            <h4>{t('common.usertype.user')} {Cookies.get('first_name')} {Cookies.get('last_name')} <LanguageButton /></h4>
            <br />
            <br />
            <div className='user-tile'>
              <img src='favicon.png' style={{ width: '150px', filter: `hue-rotate(${Cookies.get('gender') ? '57' : '287'}deg)` }} />
              <div>
                <h5>{t('common.name')}: {Cookies.get('first_name')}</h5>
                <h5>{t('common.surname')}: {Cookies.get('last_name')}</h5>
                <h5>{t('common.accounttype')}: {decyph.role}</h5>
                <h5>{t('common.email')}: {decyph.email}</h5>
                <h5>APIkey: <a style={{ cursor: 'pointer' }} onClick={copyKey}>{t('profile.apikey.button')}</a></h5>
                <br />
                <Button
                  appearance='primary' size='sm' onClick={handleLogOut} loading={loading} className='m-r-15 m-b-15 inc'
                >
                  {t('common.logout')}
                </Button>
              </div>
            </div>
          </div>}
        <div className='catalog-profile'>
          {
                    tiles.map((tile, k) => {
                      if (tile.roles.includes(userRole)) {
                        return (
                          <Link key={k} className={`product-profile ${tile.inDev && 'in-dev'}`} to={tile.link}>
                            <div style={{ display: 'flex' }}>
                              <FontAwesomeIcon icon={tile.icon} className='fa-icon-profile' />
                              <div className='product__info-profile'>
                                <div className='product__title'>{tile.title}</div>
                                <div className='product__description-profile'>{tile.subtitle}</div>
                              </div>
                            </div>
                          </Link>
                        )
                      }
                    }
                    )
                }
        </div>
      </div>
    </>
  )
}

export default Profile
