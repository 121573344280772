import React, { createContext, Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import 'rsuite/dist/styles/rsuite-default.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import './styles/index.css'
import './styles/auth.css'
// import './styles/aboutus.css'
import './styles/calcmodal.css'
import './styles/title.css'
import { CookiesProvider } from 'react-cookie'
import './i18n'

export const Context = createContext(null)

ReactDOM.render(
  <Suspense fallback={(<div>Loading</div>)}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </Suspense>,
  document.getElementById('root')
)
