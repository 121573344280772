import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button } from 'rsuite'
import LanguageButton from '../components/LanguageButton'
import { deleteDiagnosis, getOwnHistory } from '../http/API'
import tempStyle from '../components/inTime'
import HomeButton from '../components/HomeButton'

function History () {
  const { t } = useTranslation()

  const [updateKey, update] = useState(0)
  const [data, setData] = useState()
  useEffect(async () => {
    await getOwnHistory().then((res) => {
      const response = res.response
      if (response.success) {
        setData(response.history)
      } else {
        Alert.error(response.message)
      }
    })
  }, [updateKey])
  const deleteEntry = async (id) => {
    try {
      await deleteDiagnosis(id).then((res) => {
        const response = res.response
        if (response.success) {
          Alert.success(response.message)
          update(() => updateKey + 1)
        } else {
          Alert.error(response.message)
        }
      })
    } catch (e) {
      Alert.error(t('error.connectionerror'))
    }
  }

  return (
    <div className='datamodal-wrapper' style={tempStyle}>
      <div id='datasave' className='calcmodal-body data'>
        <h3><HomeButton />{t('history.title')} <LanguageButton /></h3>
        <div className='history-wrap'>
          {
          data && data?.map((e, key) =>
            <div className='diagnosis-tile' key={key}>
              <h4>{t('history.subtitle')} {new Date(e.updatedAt).toLocaleString('ru')}</h4>
              <hr />
              <h5>{t('history.input_data')}</h5>
              <p>{t('history.hb')}: {e.HB.toFixed(2)} {t('common.measure.gram')}/{t('common.measure.liter')}</p>
              <p>{t('history.hct')}: {(e.HCT * 100).toFixed(2)} %</p>
              <p>{t('history.rbc')}: {e.RBC.toFixed(2)}*10<sup>12</sup>/{t('common.measure.liter')}</p>
              {e.Fer && <p>{t('history.fer')}: {e.Fer.toFixed(2)} {t('common.measure.micro')}{t('common.measure.gram')}/{t('common.measure.liter')}</p>}
              {e.B12 && <p>B12: {e.B12.toFixed(2)} {t('common.measure.nano')}{t('common.measure.gram')}/{t('common.measure.liter')}</p>}
              <hr />
              <h5>{t('history.indices')}:</h5>
              <p>MCH: {e.MCH.toFixed(2)} {t('common.measure.pico')}{t('common.measure.gram')}</p>
              <p>MCHC: {e.MCHC.toFixed(2)} %</p>
              <p>MCV: {e.MCV.toFixed(2)} {t('common.measure.femto')}{t('common.measure.liter')}</p>
              <hr />
              <h5>{t('history.result')}:</h5>
              <b>{e.result_anemia ? t('common.anemia') : t('common.no_anemia')}</b>
              {e.result_anemia && !e.result_micro && !e.result_macro && !e.result_normo && <b>{t('history.type.undefined')}</b>}
              {e.result_anemia && e.result_micro && <b>{t('history.type.micro')}</b>}
              {e.result_anemia && e.result_macro && <b>{t('history.type.macro')}</b>}
              {e.result_anemia && e.result_normo && <b>{t('history.type.normo')}</b>}
              {e.result_anemia && !e.result_ZhDA && !e.result_AHZ && !e.result_B12 && <b>{t('history.char.undefined')}</b>}
              {e.result_anemia && e.result_ZhDA && <b>{t('history.char.zhda')}</b>}
              {e.result_anemia && e.result_AHZ && <b>{t('history.char.ahz')}</b>}
              {e.result_anemia && e.result_B12 && <b>{t('history.char.b12')}</b>}
              <hr />
              <Button onClick={() => deleteEntry(e.id)} className='delete-button'>{t('history.delete')}</Button>
            </div>
          )
        }
          {
          data && data.length === 0 && <h4>{t('history.nodata')}</h4>
        }
        </div>
      </div>
    </div>
  )
}

export default History
